<template>
  <MainLayout v-if="!loading">
    <q-layout view="hHh lpr fff">
      <q-page-container :class="['window-height', { 'q-pa-md': !isShowSidebar }]">
        <div :class="['full-height column border-radius-14', { 'auth-bg': !isShowSidebar }]">
          <div class="row col">
            <div
              v-if="!isShowSidebar"
              class="col-8 q-pa-xl"
            >
              <AuthHeader/>
            </div>
            <div :class="['col-4', isShowSidebar ? 'q-pa-md' : 'q-pa-xl']">
              <div :class="['border-radius-14 full-width full-height column justify-between', { 'q-pa-lg-xl q-pa-md-lg auth-bg': isShowSidebar }]">
                <div>
                  <div
                    v-if="isShowSidebar"
                    class="q-pb-xl"
                  >
                    <AuthHeader justify-between/>
                  </div>
                  <router-view/>
                </div>
              </div>
            </div>
            <div
              v-if="isShowSidebar"
              class="col-8 q-py-md q-pr-md"
            >
              <div class="bg-grey-3 full-height full-width border-radius-14 flex justify-center items-center q-pa-xl">
                <Banner
                  :type="ENUMS.BANNER_TYPES.BIG_BANNER"
                  :location="ENUMS.BANNER_LOCATIONS.LOGIN"
                />
              </div>
            </div>
          </div>
          <MainFooter/>
        </div>
      </q-page-container>
    </q-layout>
  </MainLayout>
</template>

<script>
import Banner from 'common/components/Banner';
import MainFooter from 'common/components/MainFooter';
import AuthHeader from 'common/components/auth/AuthHeader';
import handleError from 'common/helpers/handleError';
import MainLayout from 'common/layouts/MainLayout';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'AuthLayout',
  components: {
    Banner,
    MainFooter,
    MainLayout,
    AuthHeader,
  },
  props: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('app', ['layout']),
    ...mapGetters('app', ['defaultLanguage', 'getBanner']),
    isShowSidebar() {
      return this.getBanner(ENUMS.BANNER_TYPES.BIG_BANNER, ENUMS.BANNER_LOCATIONS.LOGIN) !== undefined;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('app', ['getLayout']),
    async init() {
      try {
        if (this.layout) {
          return;
        }
        this.loading = true;
        this.$q.loading.show();
        await this.getLayout();
        this.$i18n.locale = this.defaultLanguage;
      } catch (error) {
        handleError(error);
      } finally {
        this.loading = false;
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-bg {
  background: rgb(246, 253, 251);
  background: linear-gradient(118deg, rgba(246, 253, 251, 1) 0%, rgba(189, 235, 220, 1) 27%, rgba(246, 253, 251, 1) 61%, rgba(213, 241, 232, 1) 93%, rgba(246, 253, 251, 1) 100%);
}
</style>
